import React from "react"
import SEO from '../components/seo'
import Layout from "../containers/layout/layout"
import Header from '../containers/layout/header'
import Footer from '../containers/layout/footer'
import BookingTitleArea from '../containers/booking/booking-title-area'
import BookingContentArea from '../containers/booking/booking-content-area'

const BookingPage = () => (
  <Layout>
    <SEO 
        title="How to book a class"
        description="Steps to book a class"
        keywords={["classes", "booking", "class2.click"]}
    />
    <Header/>
    <div className="main-content">
        <BookingTitleArea/>
        <BookingContentArea/>
    </div>
    <Footer/>
  </Layout>
) 

export default BookingPage
