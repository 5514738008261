import React from 'react'
import {Container, Row, Col} from 'reactstrap'
import Text from '../../../components/shared/text'
import {BookingContentWrap, BookingContentTop} from './booking-content-area.stc'

const BookingContentArea = (props) => {
    const {textCSS} = props;
    return (
        <BookingContentWrap>
            <Container>
                <Row className="justify-content-center">
                    <Col lg={10} xl={8}>
                        <BookingContentTop>
                            <Text {...textCSS}>
                                Please follow these instructions to book a class:
                            </Text>
                                <ul>
                                    <li>Visit <a href="https://app.class2.click/lotusmoonlight" target="_blank" rel="noreferrer">https://app.class2.click/lotusmoonlight</a></li>
                                    <li>If you do not have an account yet, please create one.</li>
                                    <li>Log into your account.</li>
                                    <li>Credit your account if necessary.</li>
                                    <li>Book from the available classes.</li>
                                    <li>Just before the class starts, click Show details and then follow the Zoom link.</li>
                                </ul>
                        </BookingContentTop>
                    </Col>
                </Row>
            </Container>
        </BookingContentWrap>
    )
}

BookingContentArea.defaultProps = {
    titleCSS: {
        as: "h5",
        fontSize: [1],
        textTransform: "capitalize",
        mb: "17px"
    },
    headingCSS: {
        as: 'h3',
        color: 'secondaryColor',
        fontSize: '1.8em',
        // lineHeight: 1.375,
        mb: '0px',
        mt: '55px',
    },
    subHeadingCSS: {
        as: 'h4',
        color: 'secondaryColor',
        fontSize: '1.5em',
        // lineHeight: 1.375,
        mb: '10px',
        mt: '25px',
    },
    taglineCSS: {
        color: "secondaryColor",
        fontSize: [4, 5],
        fontWeight: "bold",
        lineHeight: [1.4, 1.375],
        mt: '30px'
    },
    textCSS: {
        mb: '10px'
    }
}

export default BookingContentArea


  